import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import RouterCustom from "./router";
import "./style/style.scss";
import { CartProvider } from "context/CartContext";
import { AuthProvider } from "context/AuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <AuthProvider>
        <BrowserRouter>
            <CartProvider>
                <RouterCustom />
            </CartProvider>
        </BrowserRouter>
    </AuthProvider>
);
