// CartContext.js

import { createContext, useContext, useState } from "react";

const CartContext = createContext();

export const useCart = () => {
    return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);

    // Thêm hoặc cập nhật số lượng sản phẩm vào giỏ
    const addToCart = (product) => {
        const existingProduct = cart.find((item) => item.id === product.id);

        if (existingProduct) {
            // Nếu sản phẩm đã có trong giỏ, cập nhật số lượng
            const updatedCart = cart.map((item) => (item.id === product.id ? { ...item, quantity: item.quantity + product.quantity } : item));
            setCart(updatedCart);
        } else {
            // Nếu chưa có, thêm sản phẩm vào giỏ với số lượng ban đầu là 1
            setCart([...cart, { ...product, quantity: product.quantity || 1 }]);
        }
    };

    // Cập nhật số lượng sản phẩm trong giỏ
    const updateCartQuantity = (productId, quantity) => {
        if (quantity < 1) return; // Không cho phép số lượng nhỏ hơn 1
        const updatedCart = cart.map((item) => (item.id === productId ? { ...item, quantity } : item));
        setCart(updatedCart);
    };

    // Xóa sản phẩm khỏi giỏ
    const removeFromCart = (productId) => {
        const updatedCart = cart.filter((item) => item.id !== productId);
        setCart(updatedCart);
    };

    return <CartContext.Provider value={{ cart, addToCart, updateCartQuantity, removeFromCart }}>{children}</CartContext.Provider>;
};
