import { Component } from "react";
import { ROUTERS } from "./utils/router";
import HomePage from "./pages/users/homePage";
import { Route, Router, Routes } from "react-router-dom";
import MasterLayout from "./pages/users/theme/masterLayout";
import ProfilePage from "./pages/users/profilePage";
import ProductsPage from "pages/users/productsPage";
import ProductDetailPage from "pages/users/productDetailPage";
import BlogPage from "pages/users/blogPage";
import { MdContactPage } from "react-icons/md";
import ContactPage from "pages/users/contactPage";
import ShoppingCartPage from "pages/users/shoppingCartPage";
import CheckoutPage from "pages/users/CheckoutPage";
import LoginPage from "pages/users/loginPage";
import RegisterPage from "pages/users/registerPage";

const renderUserRouter = () => {
    const userRouters = [
        {
            path: ROUTERS.USERS.HOME,
            component: <HomePage />,
        },
        {
            path: ROUTERS.USERS.PROFILE,
            component: <ProfilePage />,
        },
        {
            path: ROUTERS.USERS.PRODUCTS,
            component: <ProductsPage />,
        },
        {
            path: ROUTERS.USERS.PRODUCT,
            component: <ProductDetailPage />,
        },
        {
            path: ROUTERS.USERS.SHOPPING_CART,
            component: <ShoppingCartPage />,
        },
        {
            path: ROUTERS.USERS.CHECKOUT_PAGE,
            component: <CheckoutPage />,
        },
        {
            path: ROUTERS.USERS.BLOG,
            component: <BlogPage />,
        },
        {
            path: ROUTERS.USERS.CONTACT,
            component: <ContactPage />,
        },
        {
            path: ROUTERS.USERS.LOGIN,
            component: <LoginPage />,
        },
        {
            path: ROUTERS.USERS.REGISTER,
            component: <RegisterPage />,
        },
    ];
    return (
        <MasterLayout>
            <Routes>
                {userRouters.map((item, key) => (
                    <Route key={key} path={item.path} element={item.component} />
                ))}
            </Routes>
        </MasterLayout>
    );
};

const RouterCustom = () => {
    return renderUserRouter();
};

export default RouterCustom;
