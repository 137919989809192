import { memo, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../theme/breadcrumb";
import "./style.scss";

import card1 from "assets/users/images/card/card1.png";
import card2 from "assets/users/images/card/card2.png";
import card3 from "assets/users/images/card/card3.png";
import card4 from "assets/users/images/card/card4.png";
import card5 from "assets/users/images/card/card5.png";
import card6 from "assets/users/images/card/card6.png";
import card7 from "assets/users/images/card/card7.png";
import card8 from "assets/users/images/card/card8.png";
import card9 from "assets/users/images/card/card9.png";
import card10 from "assets/users/images/card/card10.png";
import card11 from "assets/users/images/card/card11.png";
import card12 from "assets/users/images/card/card12.png";
import card13 from "assets/users/images/card/card13.png";
import card14 from "assets/users/images/card/card14.png";
import card15 from "assets/users/images/card/card15.png";
import card16 from "assets/users/images/card/card16.png";

import { ProductCard } from "component";

const ProductsPage = () => {
    const allProducts = [
        {
            id: 1,
            img: card1,
            name: "MGEX 1/100 Strike Freedom Gundam",
            price: 3600000,
            category: "MGEX",
            status: "in-stock",
            date: "2024-11-01",
        },
        {
            id: 2,
            img: card2,
            name: "PG 1/60 RX-0 UNICORN GUNDAM",
            price: 4380000,
            category: "PG",
            status: "in-stock",
            date: "2024-12-01",
        },
        {
            id: 3,
            img: card3,
            name: "MG 1/100 ASW-G-08 GUNDAM BARBATOS",
            price: 1000000,
            category: "MG",
            status: "out-of-stock",
            date: "2024-08-01",
        },
        {
            id: 4,
            img: card4,
            name: "MG 1/100 GUNDAM DYNAMES",
            price: 1100000,
            category: "MG",
            status: "in-stock",
            date: "2024-09-15",
        },
        {
            id: 5,
            img: card5,
            name: "PG 1/60 GUNDAM EXIA",
            price: 4550000,
            category: "PG",
            status: "in-stock",
            date: "2024-07-20",
        },
        {
            id: 6,
            img: card6,
            name: "MG 1/100 WING ZERO EW Verka",
            price: 1250000,
            category: "MG",
            status: "out-of-stock",
            date: "2024-10-25",
        },
        {
            id: 7,
            img: card7,
            name: "RG 1/144 NU GUNDAM",
            price: 1180000,
            category: "RG",
            status: "in-stock",
            date: "2024-11-23",
        },
        {
            id: 8,
            img: card8,
            name: "RG 1/144 RX-93FF NU GUNDAM SIDE F - GUNDAM FUKUOKA LIMITED",
            price: 2080000,
            category: "RG",
            status: "in-stock",
            date: "2024-10-25",
        },
        {
            id: 9,
            img: card9,
            name: "RG 1/144 EXPANSION EFFECT UNIT FOR STRIKE FREEDOM GUNDAM - WINGS OF SKY",
            price: 690000,
            category: "RG",
            status: "out-of-stock",
            date: "2024-01-22",
        },
        {
            id: 10,
            img: card10,
            name: "RG 1/144 SAZABI",
            price: 1250000,
            category: "RG",
            status: "in-stock",
            date: "2024-4-12",
        },
        {
            id: 11,
            img: card11,
            name: "HG CE 1/144 MIGHTY STRIKE FREEDOM GUNDAM",
            price: 600000,
            category: "HG", // Ví dụ category là HG
            status: "in-stock",
            date: "2024-06-05",
        },
        {
            id: 12,
            img: card12,
            name: "HG CE 1/144 IMMORTAL JUSTICE GUNDAM",
            price: 510000,
            category: "HG",
            status: "in-stock",
            date: "2024-02-16",
        },
        {
            id: 13,
            img: card13,
            name: "HG 1/144 OO GUNDAM SEVEN SWORD/G",
            price: 425000,
            category: "HG",
            status: "out-of-stock",
            date: "2024-07-19",
        },
        {
            id: 14,
            img: card14,
            name: "HG CE 1/144 RISING FREEDOM GUNDAM",
            price: 560000,
            category: "HG",
            status: "in-stock",
            date: "2024-08-23",
        },
        {
            id: 15,
            img: card15,
            name: "SD EX STANDARD RISING FREEDOM GUNDAM",
            price: 175000,
            category: "SD",
            status: "out-of-stock",
            date: "2024-12-25",
        },
        {
            id: 16,
            img: card16,
            name: "SD EX STANDARD WING GUNDAM ZERO",
            price: 165000,
            category: "SD",
            status: "in-stock",
            date: "2024-04-01", // Theo ngày
        },
    ];

    const [sortOption, setSortOption] = useState("Tất cả");
    const sorts = ["Tất cả", "Nổi bật", "Mới đến cũ", "Cũ đến mới", "Giá từ thấp đến cao", "Giá từ cao đến thấp"];

    const [priceRange, setPriceRange] = useState("");
    const priceOptions = [
        { label: "Dưới 500.000", value: "0-500000" },
        { label: "Từ 500.000 đến 1.000.000", value: "500000-1000000" },
        { label: "Từ 1.000.000 đến 2.000.000", value: "1000000-2000000" },
        { label: "Từ 2.000.000 đến 3.000.000", value: "2000000-3000000" },
        { label: "Trên 3.000.000", value: "3000000-" },
    ];

    const [category, setCategory] = useState("");

    const [status, setStatus] = useState("");

    const [searchQuery, setSearchQuery] = useState("");

    const [filteredProducts, setFilteredProducts] = useState(allProducts);

    const location = useLocation();

    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const searchFromUrl = queryParams.get("search");
    const categoryFromUrl = queryParams.get("category"); // Lấy category từ URL

    // Cập nhật searchQuery khi URL thay đổi
    useEffect(() => {
        if (searchFromUrl) {
            setSearchQuery(searchFromUrl); // Set the search query from the URL parameter
        } else {
            setSearchQuery(""); // Reset if no search query is provided
        }
    }, [searchFromUrl]);

    // Cập nhật URL khi searchQuery thay đổi
    useEffect(() => {
        if (searchQuery) {
            // Điều hướng tới /san-pham với query string mới
            navigate(`/san-pham?search=${searchQuery}`, { replace: true });
        }
    }, [searchQuery, navigate]);

    // Cập nhật category từ URL khi URL thay đổi
    useEffect(() => {
        if (categoryFromUrl) {
            setCategory(categoryFromUrl); // Set category từ URL parameter
        } else {
            setCategory(""); // Reset nếu không có category
        }
    }, [categoryFromUrl]);

    // Filter sản phẩm dựa trên các filters
    useEffect(() => {
        let filtered = [...allProducts];

        // Lọc theo tên sản phẩm nếu searchQuery có giá trị
        if (searchQuery) {
            filtered = filtered.filter((product) => product.name.toLowerCase().includes(searchQuery.toLowerCase()));
        }

        // Lọc theo mức giá
        if (priceRange) {
            const [minPrice, maxPrice] = priceRange.split("-").map(Number);
            filtered = filtered.filter((product) => {
                if (maxPrice) return product.price >= minPrice && product.price <= maxPrice;
                return product.price >= minPrice;
            });
        }

        // Lọc theo loại sản phẩm
        if (category) filtered = filtered.filter((product) => product.category === category);

        // Lọc theo tình trạng
        if (status) filtered = filtered.filter((product) => product.status === status);

        // Sắp xếp sản phẩm
        switch (sortOption) {
            case "Giá từ thấp đến cao":
                filtered.sort((a, b) => a.price - b.price);
                break;
            case "Giá từ cao đến thấp":
                filtered.sort((a, b) => b.price - a.price);
                break;
            case "Mới đến cũ":
                filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
                break;
            case "Cũ đến mới":
                filtered.sort((a, b) => new Date(a.date) - new Date(b.date));
                break;
            default:
                break;
        }

        setFilteredProducts(filtered);
    }, [priceRange, sortOption, category, status, searchQuery]); // Thêm searchQuery vào dependencies

    const [visibleProducts, setVisibleProducts] = useState(6); // Số sản phẩm hiển thị ban đầu
    // Hàm để hiển thị thêm sản phẩm
    const loadMore = () => {
        setVisibleProducts((prev) => prev + 6); // Mỗi lần bấm "Xem thêm", hiển thị thêm 6 sản phẩm
    };

    return (
        <>
            <Breadcrumb name="Danh sách sản phẩm" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="sidebar">
                            <div className="sidebar__item">
                                <h2>Tìm kiếm theo tên</h2>
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder="Tìm sản phẩm..."
                                />
                            </div>
                            <div className="sidebar__item">
                                <h2>Mức giá</h2>
                                <select value={priceRange} onChange={(e) => setPriceRange(e.target.value)}>
                                    <option value="">Chọn mức giá</option>
                                    {priceOptions.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="sidebar__item">
                                <h2>Sắp xếp</h2>
                                <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
                                    {sorts.map((sort, index) => (
                                        <option key={index} value={sort}>
                                            {sort}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="sidebar__item">
                                <h2>Loại sản phẩm</h2>
                                <select value={category} onChange={(e) => setCategory(e.target.value)}>
                                    <option value="">Chọn loại</option>
                                    <option value="SD">SD</option>
                                    <option value="HG">HG</option>
                                    <option value="MG">MG</option>
                                    <option value="MGEX">MGEX</option>
                                    <option value="PG">PG</option>
                                    <option value="Khác">Khác</option>
                                </select>
                            </div>
                            <div className="sidebar__item">
                                <h2>Tình trạng</h2>
                                <select value={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option value="">Chọn tình trạng</option>
                                    <option value="in-stock">Còn hàng</option>
                                    <option value="out-of-stock">Hết hàng</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="row">
                            {filteredProducts.length > 0 ? (
                                // Hiển thị sản phẩm theo số lượng `visibleProducts`
                                filteredProducts.slice(0, visibleProducts).map((item) => (
                                    <div className="col-lg-4" key={item.id}>
                                        <ProductCard id={item.id} name={item.name} img={item.img} price={item.price} />
                                    </div>
                                ))
                            ) : (
                                <p>Không tìm thấy sản phẩm nào.</p>
                            )}
                        </div>

                        {/* Thêm nút "Xem thêm" để tải thêm sản phẩm */}
                        {filteredProducts.length > visibleProducts && (
                            <div className="text-center">
                                <button
                                    className="button-submit"
                                    onClick={loadMore} // Khi nhấn vào sẽ gọi hàm loadMore
                                >
                                    Xem thêm
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(ProductsPage);
