import React, { useState, useEffect } from "react";
import "./style.scss";
import Breadcrumb from "../theme/breadcrumb";

const BlogPage = () => {
    const [posts, setPosts] = useState([]);
    const [visiblePosts, setVisiblePosts] = useState(3);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Dữ liệu giả lập từ các tin tức Gunpla
        const fetchedPosts = [
            {
                title: "Cập nhật bộ kit mới: RG Akatsuki Gundam",
                date: "2 Tháng 12, 2024",
                content:
                    "Bộ kit RG Akatsuki Gundam mới sẽ được phát hành vào tháng 12, mang đến những chi tiết tuyệt vời với nhiều tính năng đặc biệt.",
                image: "/images/blog/rgakatsuki.png",
            },
            {
                title: "Gundam Metaverse mở cửa",
                date: "29 Tháng 11, 2024",
                content: "Gundam Metaverse đã mở cửa lần thứ 3 với những sự kiện hấp dẫn, cơ hội nhận quà và trải nghiệm Gunpla từ cộng đồng.",
                image: "/images/blog/gundammetaverse.png",
            },
            {
                title: "Giới thiệu bộ kit MGSD Wing Gundam Zero EW",
                date: "28 Tháng 11, 2024",
                content: "Bộ kit MGSD Wing Gundam Zero EW sẽ được ra mắt vào cuối tháng 11, với những chi tiết đặc biệt cho các fan của Gundam.",
                image: "/images/blog/winggundam.png",
            },
            {
                title: "Tin tức Gunpla mới",
                date: "10 Tháng 11, 2024",
                content: "Cập nhật các bộ kit mới, sự kiện Gunpla hấp dẫn và nhiều thông tin thú vị khác.",
                image: "/images/blog/news.png",
            },
            // Thêm nhiều bài viết khác ở đây
        ];

        setPosts(fetchedPosts);
    }, []);

    const loadMorePosts = () => {
        setIsLoading(true);
        setTimeout(() => {
            setVisiblePosts((prev) => prev + 3); // Mỗi lần nhấn nút "Xem thêm" hiển thị 3 bài viết tiếp theo
            setIsLoading(false);
        }, 500); // Giả lập delay khi tải thêm
    };

    return (
        <>
            <Breadcrumb name="Bài viết" />
            <div className="container">
                <div className="blog-page">
                    <h1>Blog Gunpla</h1>
                    <div className="blog-posts">
                        {posts.slice(0, visiblePosts).map((post, index) => (
                            <div key={index} className="post-card">
                                <h2>{post.title}</h2>
                                <p className="post-date">{post.date}</p>
                                <div className=" row">
                                    {" "}
                                    <div className="col-lg-4">
                                        <img src={post.image} alt={post.title} className="post-image" />
                                    </div>
                                    <div className="col-lg-8">
                                        <p className="post-content">{post.content}</p>
                                        <a href="#read-more" className="read-more">
                                            Đọc thêm...
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {visiblePosts < posts.length && (
                        <button onClick={loadMorePosts} className="load-more-btn">
                            {isLoading ? "Đang tải..." : "Xem thêm"}
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default BlogPage;
