import { memo, useState, useEffect } from "react";
import { FaFacebook, FaInstagram, FaUser, FaTwitter } from "react-icons/fa";
import { AiOutlineMail, AiOutlineShoppingCart, AiOutlinePhone } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { formater } from "utils/formater";
import { ROUTERS } from "utils/router";
import { useCart } from "context/CartContext";
import Slider from "react-slick";
import { useAuth } from "context/AuthContext";

import "./style.scss";

const Header = () => {
    const [menus, setMenus] = useState([
        { name: "Trang chủ", path: ROUTERS.USERS.HOME },
        { name: "Sản Phẩm", path: ROUTERS.USERS.PRODUCTS },
        { name: "Bài Viết", path: ROUTERS.USERS.BLOG },
        { name: "Liên Hệ", path: ROUTERS.USERS.CONTACT },
    ]);

    const images = ["/images/hero/1.jpg", "/images/hero/2.jpg", "/images/hero/3.jpg"];

    // Cấu hình slider
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        prevArrow: <button className="slick-prev"></button>,
        nextArrow: <button className="slick-next"></button>,
        pauseOnHover: true,
    };

    const location = useLocation();
    const [isHome, setIsHome] = useState(location.pathname.length <= 1);

    useEffect(() => {
        const isHome = location.pathname.length <= 1;
        setIsHome(isHome);
    }, [location.pathname.length <= 1]);

    const { cart } = useCart();

    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (searchQuery.trim()) {
            navigate(`${ROUTERS.USERS.PRODUCTS}?search=${searchQuery}`);
        }
    };
    const { user, logout } = useAuth();

    const handleLogout = () => {
        logout();
        navigate("/"); // Quay lại trang chủ khi đăng xuất
    };

    return (
        <>
            <div className="header__top">
                <div className="container">
                    <div className="row">
                        <div className="col-6 header__top__left">
                            <ul>
                                <li>
                                    <AiOutlineMail />
                                    gunplashop@gmail.com
                                </li>
                                <li>Miễn phí giao hàng cho đơn từ {formater(500000)}</li>
                            </ul>
                        </div>
                        <div className="col-6 header__top__right">
                            <ul>
                                <li>
                                    <Link to={""}>
                                        <FaFacebook />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={""}>
                                        <FaInstagram />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={""}>
                                        <FaTwitter />
                                    </Link>
                                </li>
                                <li>
                                    {user ? (
                                        <span onClick={handleLogout}>Đăng xuất</span>
                                    ) : (
                                        <Link to={ROUTERS.USERS.LOGIN}>
                                            <FaUser />
                                            <span>Đăng nhập</span>
                                        </Link>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="header__logo">
                            <img src="https://gunplastory.com/wp-content/uploads/2021/08/gunplastory-logo.webp" alt="" width="250px" height="100%" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="header__menu">
                            <ul>
                                {menus?.map((menu, menuKey) => {
                                    const isActive = location.pathname === menu?.path || (menu?.name === "Trang chủ" && location.pathname === "/");
                                    return (
                                        <li key={menuKey} className={isActive ? "active" : ""}>
                                            <Link to={menu?.path} className={isActive ? "active" : ""}>
                                                {menu?.name}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="header__cart">
                            <div className="header__cart__price">
                                <span>{formater(cart.reduce((total, product) => total + product.price * product.quantity, 0))}</span>
                            </div>
                            <ul>
                                <li>
                                    <Link to={ROUTERS.USERS.SHOPPING_CART}>
                                        <AiOutlineShoppingCart /> <span>{cart.length}</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {isHome && (
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 hero__search__container">
                            <div className="hero__search">
                                <div className="hero__search__form">
                                    <form onSubmit={handleSearchSubmit}>
                                        <input
                                            type="text"
                                            placeholder="Nhập tên sản phẩm..."
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                        <button type="submit">Tìm kiếm</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 hero__search__phone">
                            <div className="hero__search__phone__icon">
                                <AiOutlinePhone />
                            </div>
                            <div className="hero__search__phone__text">
                                <span>Hotline</span>
                                <p>0888.033.529</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isHome && (
                <div className="row">
                    <div className="col-xl-12">
                        <div className="image-slider" style={{ height: "800px" }}>
                            <Slider {...settings}>
                                {images.map((image, index) => (
                                    <div key={index} className="slider-image">
                                        <img
                                            src={image}
                                            alt={`Slide ${index + 1}`}
                                            style={{
                                                width: "100%",
                                                height: "800px",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default memo(Header);
