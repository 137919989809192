import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Breadcrumb from "../theme/breadcrumb";
import "./style.scss";

// MGEX
import card1_1 from "assets/users/images/card-detail/mgex-strike/1.png";
import card1_2 from "assets/users/images/card-detail/mgex-strike/2.png";
import card1_3 from "assets/users/images/card-detail/mgex-strike/3.png";
import card1_4 from "assets/users/images/card-detail/mgex-strike/4.png";

// PG
import card2_1 from "assets/users/images/card-detail/pg-unicorn/2_1.png";
import card2_2 from "assets/users/images/card-detail/pg-unicorn/2_2.png";
import card2_3 from "assets/users/images/card-detail/pg-unicorn/2_3.png";
import card2_4 from "assets/users/images/card-detail/pg-unicorn/2_4.png";

import card5_1 from "assets/users/images/card-detail/pg-exia/5_1.png";
import card5_2 from "assets/users/images/card-detail/pg-exia/5_2.png";
import card5_3 from "assets/users/images/card-detail/pg-exia/5_3.png";
import card5_4 from "assets/users/images/card-detail/pg-exia/5_4.png";

// MG
import card3_1 from "assets/users/images/card-detail/mg-barbatos/3_1.png";
import card3_2 from "assets/users/images/card-detail/mg-barbatos/3_2.png";
import card3_3 from "assets/users/images/card-detail/mg-barbatos/3_3.png";
import card3_4 from "assets/users/images/card-detail/mg-barbatos/3_4.png";

import card4_1 from "assets/users/images/card-detail/mg-dynames/4_1.png";
import card4_2 from "assets/users/images/card-detail/mg-dynames/4_2.png";
import card4_3 from "assets/users/images/card-detail/mg-dynames/4_3.png";
import card4_4 from "assets/users/images/card-detail/mg-dynames/4_4.png";

import card6_1 from "assets/users/images/card-detail/mg-wingzero/6_1.png";
import card6_2 from "assets/users/images/card-detail/mg-wingzero/6_2.png";
import card6_3 from "assets/users/images/card-detail/mg-wingzero/6_3.png";
import card6_4 from "assets/users/images/card-detail/mg-wingzero/6_4.png";

// RG
import card7_1 from "assets/users/images/card-detail/rg-nu/7_1.png";
import card7_2 from "assets/users/images/card-detail/rg-nu/7_2.png";
import card7_3 from "assets/users/images/card-detail/rg-nu/7_3.png";
import card7_4 from "assets/users/images/card-detail/rg-nu/7_4.png";

import card8_1 from "assets/users/images/card-detail/rg-nu2/8_1.png";
import card8_2 from "assets/users/images/card-detail/rg-nu2/8_1.png";
import card8_3 from "assets/users/images/card-detail/rg-nu2/8_1.png";
import card8_4 from "assets/users/images/card-detail/rg-nu2/8_1.png";

import card9_1 from "assets/users/images/card-detail/rg-strikefreedom/9_1.png";
import card9_2 from "assets/users/images/card-detail/rg-strikefreedom/9_1.png";
import card9_3 from "assets/users/images/card-detail/rg-strikefreedom/9_1.png";
import card9_4 from "assets/users/images/card-detail/rg-strikefreedom/9_1.png";

import card10_1 from "assets/users/images/card-detail/rg-sazabi/10_1.png";
import card10_2 from "assets/users/images/card-detail/rg-sazabi/10_1.png";
import card10_3 from "assets/users/images/card-detail/rg-sazabi/10_1.png";
import card10_4 from "assets/users/images/card-detail/rg-sazabi/10_1.png";

// HG
import card11_1 from "assets/users/images/card-detail/hg-strikefreedom/11_1.png";
import card11_2 from "assets/users/images/card-detail/hg-strikefreedom/11_1.png";
import card11_3 from "assets/users/images/card-detail/hg-strikefreedom/11_1.png";
import card11_4 from "assets/users/images/card-detail/hg-strikefreedom/11_1.png";

import card12_1 from "assets/users/images/card-detail/hg-justice/12_1.png";
import card12_2 from "assets/users/images/card-detail/hg-justice/12_1.png";
import card12_3 from "assets/users/images/card-detail/hg-justice/12_1.png";
import card12_4 from "assets/users/images/card-detail/hg-justice/12_1.png";

import card13_1 from "assets/users/images/card-detail/hg-sevensword/13_1.png";
import card13_2 from "assets/users/images/card-detail/hg-sevensword/13_1.png";
import card13_3 from "assets/users/images/card-detail/hg-sevensword/13_1.png";
import card13_4 from "assets/users/images/card-detail/hg-sevensword/13_1.png";

import card14_1 from "assets/users/images/card-detail/hg-freedom/14_1.png";
import card14_2 from "assets/users/images/card-detail/hg-freedom/14_1.png";
import card14_3 from "assets/users/images/card-detail/hg-freedom/14_1.png";
import card14_4 from "assets/users/images/card-detail/hg-freedom/14_1.png";

// SD

import card15_1 from "assets/users/images/card-detail/sd-freedom/15_1.png";
import card15_2 from "assets/users/images/card-detail/sd-freedom/15_1.png";
import card15_3 from "assets/users/images/card-detail/sd-freedom/15_1.png";
import card15_4 from "assets/users/images/card-detail/sd-freedom/15_1.png";

import card16_1 from "assets/users/images/card-detail/sd-wing/16_1.png";
import card16_2 from "assets/users/images/card-detail/sd-wing/16_1.png";
import card16_3 from "assets/users/images/card-detail/sd-wing/16_1.png";
import card16_4 from "assets/users/images/card-detail/sd-wing/16_1.png";

// import card2 from "assets/users/images/card/card2.png";
// import card3 from "assets/users/images/card/card3.png";
// import card4 from "assets/users/images/card/card4.png";
// import card5 from "assets/users/images/card/card5.png";
// import card6 from "assets/users/images/card/card6.png";

import { ProductCard } from "component";
import { formater } from "utils/formater";
import { useCart } from "context/CartContext";

// Mock dữ liệu sản phẩm
const allProducts = [
    {
        id: 1,
        img: card1_1,
        name: "MGEX 1/100 Strike Freedom Gundam",
        price: 3600000,
        category: "MGEX",
        status: "in-stock",
        date: "2024-11-01",
        description: `
                Mô Hình Lắp Ráp MGEX Strike Freedom K.O
                Tình trạng: Mới nguyên hộp
                Khung xương: Có
                PHIÊN BẢN : MGEX – Master Grade Extreme
                Chiều cao: 18-25cm
                PHÂN LOẠI SP : LẮP RÁP

                – Sản phẩm nhựa cao cấp với độ sắc nét cao
                – Rèn luyện tính kiên nhẫn cho người chơi
                – Phân phối bởi Mô Hình GDC
                Các khớp cử động linh hoạt theo ý muốn.
                Người chơi sẽ thỏa sức sáng tạo và đam mê.

                QUÝ KHÁCH VUI LÒNG CHAT VỚI SHOP TRƯỚC KHI MUA HÀNG TRÁNH SẢN PHẨM HẾT HÀNG ĐỘT XUẤT

                ----------
                Quý khách có thể xem thêm các phụ kiện như kềm, nhíp, nhám, dao trong sản phẩm của shop

                Lưu ý:
                + Sản phẩm có những chi tiết nhỏ, quý khách kiểm tra trước khi lắp
                + Với những chi tiết lỗi có thể trao đổi trực tiếp với shop để hỗ trợ xử lý

                ----------
                =>> NHẬN ORDER TỪ 7-14 NGÀY ĐỐI VỚI NHỮNG MẶT HÀNG KHÔNG CÓ SẴN
                =>> MỌI CHI TIẾT XIN LIÊN HỆ VỚI CỬA HÀNG
            `,
        images: [card1_1, card1_2, card1_3, card1_4],
    },
    {
        id: 2,
        img: card2_1,
        name: "PG 1/60 RX-0 UNICORN GUNDAM",
        price: 4380000,
        category: "PG",
        status: "in-stock",
        date: "2024-12-01",
        description: `Mô Hình Gundam Bandai PG RX 0 Unicorn
                    - Sản phậm nhựa cao cấp với độ sắc nét cao
                    - Sản xuất bởi Bandai Namco – Nhật Bản Chính hãng
                    - An toàn với trẻ em 
                    - Phát triển trí não cho trẻ hiệu quả đi đôi với niềm vui thích bất tận 
                    - Rèn luyện tính kiên nhẫn cho người chơi 
                    - Thông tin cơ bản : 
                    Mô hình gundam (gunpla) là một loại mô hình nhựa được gọi là Model kit, bao gồm nhiều mảnh nhựa rời được gọi là part (bộ phận), khi lắp ráp các part lại với nhau sẽ được mô hình hoàn chỉnh. Các mảnh nhựa rời này được gắn trên khung nhựa gọi là runner. 
                    Mỗi một hộp sản phẩm Gunpla bao gồm nhiều runner và các phụ kiện liên quan, một tập sách nhỏ (manual) bên trong giới thiệu sơ lược về mẫu Gundam trong hộp và phần hướng dẫn cách lắp ráp.
                    Dòng gundam với các chi tiết hoàn hảo. o Các khớp cử động linh hoạt theo ý muốn. o Người chơi sẽ thỏa sức sáng tạo và đam mê.
                    THƯƠNG HIỆU : BANDAI 
                    – NHẬT BẢN PHIÊN BẢN : PG 1/60 tỉ lệ hoàn hảo nhất của Gundam 
                    Chiều cao: 32-35cm 
                    PHÂN LOẠI SP : LẮP RÁP QUÝ KHÁCH VUI LÒNG CHAT VỚI SHOP TRƯỚC KHI MUA HÀNG TRÁNH SẢN PHẨM HẾT HÀNG ĐỘT XUẤT
                    Quý khách có thể xem thêm các phụ kiện như kềm, nhíp, nhám, dao trong sản phẩm của shop Lưu ý: 
                    + Sản phẩm có những chi tiết nhỏ, quý khách kiểm tra trước khi lắp 
                    + Hộp sản phẩm là giấy mỏng, có thể cấn móp trong quá trình vận chuyển, mong quý khách thông cảm 
                    + Với những chi tiết lỗi có thể trao đổi trực tiếp với shop để hỗ trợ xử lý
                    =>> NHẬN ORDER TỪ 7-14 NGÀY ĐỐI VỚI NHỮNG MẶT HÀNG KHÔNG CÓ SẴN 
                    =>> MỌI CHI TIẾT XIN LIÊN HỆ VỚI CỬA HÀNG`,
        images: [card2_1, card2_2, card2_3, card2_4],
    },
    {
        id: 3,
        img: card3_1,
        name: "MG 1/100 ASW-G-08 Gundam Barbatos",
        price: 1000000,
        category: "MG",
        status: "out-of-stock",
        date: "2024-08-01",
        description: `Mô hình MG Gundam Barbatos Bandai
                - Sản phậm nhựa cao cấp với độ sắc nét cao
                - Sản xuất bởi Bandai Namco – Nhật Bản Chính hãng
                - An toàn với trẻ em
                - Phát triển trí não cho trẻ hiệu quả đi đôi với niềm vui thích bất tận
                - Rèn luyện tính kiên nhẫn cho người chơi
                - Phân phối bởi Mô Hình GDC
                - Thông tin cơ bản :
                o Mô hình gundam (gunpla) là một loại mô hình nhựa được gọi là Model kit, bao gồm nhiều mảnh nhựa rời được gọi là part (bộ phận), khi lắp ráp các part lại với nhau sẽ được mô hình hoàn chỉnh. Các mảnh nhựa rời này được gắn trên khung nhựa gọi là runner. Mỗi một hộp sản phẩm Gunpla bao gồm nhiều runner và các phụ kiện liên quan, một tập sách nhỏ (manual) bên trong giới thiệu sơ lược về mẫu Gundam trong hộp và phần hướng dẫn cách lắp ráp.
                o Dòng gundam với các chi tiết hoàn hảo.
                o Các khớp cử động linh hoạt theo ý muốn.
                o Người chơi sẽ thỏa sức sáng tạo và đam mê.

                THƯƠNG HIỆU : BANDAI – NHẬT BẢN
                PHIÊN BẢN : MG 1/100
                Chiều cao: 18-23cm
                PHÂN LOẠI SP : LẮP RÁP

                QUÝ KHÁCH VUI LÒNG CHAT VỚI SHOP TRƯỚC KHI MUA HÀNG TRÁNH SẢN PHẨM HẾT HÀNG ĐỘT XUẤT
                ----------
                Quý khách có thể xem thêm các phụ kiện như kềm, nhíp, nhám, dao trong sản phẩm của shop
                Lưu ý:
                + Sản phẩm có những chi tiết nhỏ, quý khách kiểm tra trước khi lắp
                + Với những chi tiết lỗi có thể trao đổi trực tiếp với shop để hỗ trợ xử lý
                ----------
                =>> NHẬN ORDER TỪ 7-14 NGÀY ĐỐI VỚI NHỮNG MẶT HÀNG KHÔNG CÓ SẴN
                =>> MỌI CHI TIẾT XIN LIÊN HỆ VỚI CỬA HÀNG`,
        images: [card3_1, card3_2, card3_3, card3_4],
    },
    {
        id: 4,
        img: card4_1,
        name: "MG 1/100 GUNDAM DYNAMES",
        price: 1100000,
        category: "MG",
        status: "in-stock",
        date: "2024-09-15",
        description: `Mô hình Gundam Dynames MG 
                    - Mô hình nhựa lắp ghép - Gunpla
                    Hãng Sản Xuất: Bandai

                    Tỉ lệ: MG – 1/100

                    Chiều cao: 18-19cm

                    Tình trạng: Mới nguyên hộp

                    Nguồn gốc: Nhật Bản

                    Khung xương: Có
                    - Xuất Xứ: Nhật Bản
                    - Thương Hiệu: Chính Hãng Bandai Nhật Bản 100%
                    - Chất Liệu: PS; ABS; PP; PVC không độc hại, an toàn cho trẻ em
                    - Phù hợp với độ tuổi từ 8 tuổi trở lên
                    - Sản phẩm có khớp có thể cử động nhiều dáng khác nhau, tạo nhiều dáng đẹp
                    - Đây là mô hình mới chưa được lắp ráp, người chơi lắp ráp theo sách hướng dẫn kèm theo
                    - Sản phẩm lắp ghép dùng chốt bấm không cần dùng keo dán
                    - Yêu cầu dụng cụ hỗ trợ cơ bản như kềm chuyên dụng và dao chuyên dụng
                    Lưu ý:
                    + Sản phẩm có những chi tiết nhỏ, quý khách kiểm tra trước khi lắp
                    + Hộp sản phẩm là giấy mỏng, có thể cấn móp trong quá trình vận chuyển, mong quý khách thông cảm
                    + Với những chi tiết lỗi có thể trao đổi trực tiếp với shop để hỗ trợ xử lý
                    ----------
                    =>> NHẬN ORDER TỪ 7-14 NGÀY ĐỐI VỚI NHỮNG MẶT HÀNG KHÔNG CÓ SẴN
                    =>> MỌI CHI TIẾT XIN LIÊN HỆ VỚI CỬA HÀNG`,
        images: [card4_1, card4_2, card4_3, card4_4],
    },
    {
        id: 5,
        img: card5_1,
        name: "PG 1/60 GUNDAM EXIA",
        price: 4550000,
        category: "PG",
        status: "in-stock",
        date: "2024-07-20",
        description: `Mô hình lắp ráp PG Gundam Exia Bandai
                    - Sản phậm nhựa cao cấp với độ sắc nét cao
                    - Sản xuất bởi Bandai Namco – Nhật Bản Chính hãng
                    - An toàn với trẻ em
                    - Phát triển trí não cho trẻ hiệu quả đi đôi với niềm vui thích bất tận
                    - Rèn luyện tính kiên nhẫn cho người chơi
                    - Phân phối bởi Mô Hình GDC
                    - Thông tin cơ bản :
                    Mô hình gundam (gunpla) là một loại mô hình nhựa được gọi là Model kit, bao gồm nhiều mảnh nhựa rời được gọi là part (bộ phận), khi lắp ráp các part lại với nhau sẽ được mô hình hoàn chỉnh. Các mảnh nhựa rời này được gắn trên khung nhựa gọi là runner. Mỗi một hộp sản phẩm Gunpla bao gồm nhiều runner và các phụ kiện liên quan, một tập sách nhỏ (manual) bên trong giới thiệu sơ lược về mẫu Gundam trong hộp và phần hướng dẫn cách lắp ráp.
                    Dòng gundam với các chi tiết hoàn hảo.
                    Các khớp cử động linh hoạt theo ý muốn.
                    Người chơi sẽ thỏa sức sáng tạo và đam mê.

                    THƯƠNG HIỆU : BANDAI – NHẬT BẢN
                    PHIÊN BẢN : PG 1/60 tỉ lệ hoàn hảo nhất của Gundam
                    Chiều cao: 32-35cm
                    PHÂN LOẠI SP : LẮP RÁP

                    QUÝ KHÁCH VUI LÒNG CHAT VỚI SHOP TRƯỚC KHI MUA HÀNG TRÁNH SẢN PHẨM HẾT HÀNG ĐỘT XUẤT
                    ----------
                    Quý khách có thể xem thêm các phụ kiện như kềm, nhíp, nhám, dao trong sản phẩm của shop
                    Lưu ý:
                    + Sản phẩm có những chi tiết nhỏ, quý khách kiểm tra trước khi lắp
                    + Hộp sản phẩm là giấy mỏng, có thể cấn móp trong quá trình vận chuyển, mong quý khách thông cảm
                    + Với những chi tiết lỗi có thể trao đổi trực tiếp với shop để hỗ trợ xử lý
                    ----------
                    =>> NHẬN ORDER TỪ 7-14 NGÀY ĐỐI VỚI NHỮNG MẶT HÀNG KHÔNG CÓ SẴN
                    =>> MỌI CHI TIẾT XIN LIÊN HỆ VỚI CỬA HÀNG`,
        images: [card5_1, card5_2, card5_3, card5_4],
    },
    {
        id: 6,
        img: card6_1,
        name: "MG 1/100 WING ZERO EW VERKA",
        price: 1250000,
        category: "MG",
        status: "in-stock",
        date: "2024-10-25",
        description: `Mô Hình Lắp Ráp MG Wing Gundam Zero EW ver.Ka Bandai 
        - Sản phậm nhựa cao cấp với độ sắc nét cao 
        - Sản xuất bởi Bandai Namco – Nhật Bản Chính hãng 
        - An toàn với trẻ em 
        - Phát triển trí não cho trẻ hiệu quả đi đôi với niềm vui thích bất tận 
        - Rèn luyện tính kiên nhẫn cho người chơi 
        - Phân phối bởi Mô Hình GDC 
        - Thông tin cơ bản : 
        Mô hình gundam (gunpla) là một loại mô hình nhựa được gọi là Model kit, bao gồm nhiều mảnh nhựa rời được gọi là part (bộ phận), khi lắp ráp các part lại với nhau sẽ được mô hình hoàn chỉnh. 
        Các mảnh nhựa rời này được gắn trên khung nhựa gọi là runner. 
        Mỗi một hộp sản phẩm Gunpla bao gồm nhiều runner và các phụ kiện liên quan, một tập sách nhỏ (manual) bên trong giới thiệu sơ lược về mẫu Gundam trong hộp và phần hướng dẫn cách lắp ráp. 
        Dòng gundam với các chi tiết hoàn hảo. o Các khớp cử động linh hoạt theo ý muốn. o Người chơi sẽ thỏa sức sáng tạo và đam mê. 
        THƯƠNG HIỆU : BANDAI – NHẬT BẢN 
        PHIÊN BẢN : MG 1/100 
        Chiều cao: 23cm 
        PHÂN LOẠI: SP LẮP RÁP 
        QUÝ KHÁCH VUI LÒNG CHAT VỚI SHOP TRƯỚC KHI MUA HÀNG TRÁNH SẢN PHẨM HẾT HÀNG ĐỘT XUẤT 
        =>> NHẬN ORDER TỪ 7-14 NGÀY ĐỐI VỚI NHỮNG MẶT HÀNG KHÔNG CÓ SẴN 
        =>> MỌI CHI TIẾT XIN LIÊN HỆ VỚI CỬA HÀNG`,
        images: [card6_1, card6_2, card6_3, card6_4],
    },
    {
        id: 7,
        img: card7_1,
        name: "RG 1/144 NU GUNDAM",
        price: 1180000,
        category: "RG",
        status: "in-stock",
        date: "2024-11-23",
        description: `MÔ HÌNH GUNDAM BANDAI RG 32 1/144 RX-93 NU GUNDAM GUNDAM UC
                    - Sản phậm nhựa cao cấp với độ sắc nét cao
                    - Sản xuất bởi Bandai Namco – Nhật Bản Chính hãng
                    - An toàn với trẻ em
                    - Phát triển trí não cho trẻ hiệu quả đi đôi với niềm vui thích bất tận
                    - Rèn luyện tính kiên nhẫn cho người chơi
                    - Phân phối bởi Mô Hình GDC
                    - Thông tin cơ bản :
                    Mô hình gundam (gunpla) là một loại mô hình nhựa được gọi là Model kit, bao gồm nhiều mảnh nhựa rời được gọi là part (bộ phận), khi lắp ráp các part lại với nhau sẽ được mô hình hoàn chỉnh. Các mảnh nhựa rời này được gắn trên khung nhựa gọi là runner. Mỗi một hộp sản phẩm Gunpla bao gồm nhiều runner và các phụ kiện liên quan, một tập sách nhỏ (manual) bên trong giới thiệu sơ lược về mẫu Gundam trong hộp và phần hướng dẫn cách lắp ráp.
                    Dòng gundam với các chi tiết hoàn hảo.
                    Các khớp cử động linh hoạt theo ý muốn.
                    Người chơi sẽ thỏa sức sáng tạo và đam mê.

                    THƯƠNG HIỆU : BANDAI – NHẬT BẢN
                    PHIÊN BẢN : RG Real Grade
                    Chiều cao: 16cm
                    PHÂN LOẠI SP : LẮP RÁP

                    QUÝ KHÁCH VUI LÒNG CHAT VỚI SHOP TRƯỚC KHI MUA HÀNG TRÁNH SẢN PHẨM HẾT HÀNG ĐỘT XUẤT
                    ----------
                    Quý khách có thể xem thêm các phụ kiện như kềm, nhíp, nhám, dao trong sản phẩm của shop
                    Lưu ý:
                    + Sản phẩm có những chi tiết nhỏ, quý khách kiểm tra trước khi lắp
                    + Hộp sản phẩm là giấy mỏng, có thể cấn móp trong quá trình vận chuyển, mong quý khách thông cảm
                    + Với những chi tiết lỗi có thể trao đổi trực tiếp với shop để hỗ trợ xử lý
                    ----------
                    =>> NHẬN ORDER TỪ 7-14 NGÀY ĐỐI VỚI NHỮNG MẶT HÀNG KHÔNG CÓ SẴN
                    =>> MỌI CHI TIẾT XIN LIÊN HỆ VỚI CỬA HÀNG`,
        images: [card7_1, card7_2, card7_3, card7_4],
    },
    {
        id: 8,
        img: card8_1,
        name: "RG 1/144 RX-93FF NU GUNDAM SIDE F - GUNDAM FUKUOKA LIMITED",
        price: 2080000,
        category: "RG",
        status: "in-stock",
        date: "2024-10-25",
        description: `Mô Hình Lắp Ráp RG 1/144 GUNDAM SIDE-F RX-93ff ν-GUNDAM Bandai
                    - Sản phậm nhựa cao cấp với độ sắc nét cao
                    - Sản xuất bởi Bandai Namco – Nhật Bản Chính hãng
                    - An toàn với trẻ em
                    - Phát triển trí não cho trẻ hiệu quả đi đôi với niềm vui thích bất tận
                    - Rèn luyện tính kiên nhẫn cho người chơi
                    - Phân phối bởi Mô Hình GDC
                    - Thông tin cơ bản :
                    Mô hình gundam (gunpla) là một loại mô hình nhựa được gọi là Model kit, bao gồm nhiều mảnh nhựa rời được gọi là part (bộ phận), khi lắp ráp các part lại với nhau sẽ được mô hình hoàn chỉnh. Các mảnh nhựa rời này được gắn trên khung nhựa gọi là runner. Mỗi một hộp sản phẩm Gunpla bao gồm nhiều runner và các phụ kiện liên quan, một tập sách nhỏ (manual) bên trong giới thiệu sơ lược về mẫu Gundam trong hộp và phần hướng dẫn cách lắp ráp.
                    Dòng gundam với các chi tiết hoàn hảo.
                    Các khớp cử động linh hoạt theo ý muốn.
                    Người chơi sẽ thỏa sức sáng tạo và đam mê.

                    THƯƠNG HIỆU : BANDAI – NHẬT BẢN
                    PHIÊN BẢN : RG 1/144
                    Chiều cao: 13-16cm
                    PHÂN LOẠI SP : LẮP RÁP

                    QUÝ KHÁCH VUI LÒNG CHAT VỚI SHOP TRƯỚC KHI MUA HÀNG TRÁNH SẢN PHẨM HẾT HÀNG ĐỘT XUẤT
                    ----------
                    Quý khách có thể xem thêm các phụ kiện như kềm, nhíp, nhám, dao trong sản phẩm của shop
                    Lưu ý:
                    + Sản phẩm có những chi tiết nhỏ, quý khách kiểm tra trước khi lắp
                    + Với những chi tiết lỗi có thể trao đổi trực tiếp với shop để hỗ trợ xử lý
                    ----------
                    =>> NHẬN ORDER TỪ 7-14 NGÀY ĐỐI VỚI NHỮNG MẶT HÀNG KHÔNG CÓ SẴN
                    =>> MỌI CHI TIẾT XIN LIÊN HỆ VỚI CỬA HÀNG`,
        images: [card8_1, card8_2, card8_3, card8_4],
    },
    {
        id: 9,
        img: card9_1,
        name: "RG 1/144 EXPANSION EFFECT UNIT FOR STRIKE FREEDOM GUNDAM - WINGS OF SKY",
        price: 690000,
        category: "RG",
        status: "out-of-stock",
        date: "2024-01-22",
        description: "Mô tả sản phẩm RG 1/144 EXPANSION EFFECT UNIT FOR STRIKE FREEDOM GUNDAM - WINGS OF SKY",
        images: [card9_1, card9_2, card9_3, card9_4],
    },
    {
        id: 10,
        img: card10_1,
        name: "RG 1/144 SAZABI",
        price: 1250000,
        category: "RG",
        status: "in-stock",
        date: "2024-4-12",
        description: `Mô hình RG SAZABI BANDAI
                    Mẫu rg có khung xương cử động linh hoạt
                    cao 13-14cm
                    Xuất xứ: Nhật Bản
                    - Sản phậm nhựa cao cấp với độ sắc nét cao
                    - Sản xuất bởi Bandai Namco – Nhật Bản
                    - An toàn với trẻ em
                    - Phát triển trí não cho trẻ hiệu quả đi đôi với niềm vui thích bất tận
                    - Rèn luyện tính kiên nhẫn cho người chơi
                    - Phân phối bởi mô hình GDC
                    - Thông tin cơ bản :
                     Mô hình gundam (gunpla) là một loại mô hình nhựa được gọi là Model kit, bao gồm nhiều mảnh nhựa rời được gọi là part (bộ phận), khi lắp ráp các part lại với nhau sẽ được mô hình hoàn chỉnh. Các mảnh nhựa rời này được gắn trên khung nhựa gọi là runner. Mỗi một hộp sản phẩm Gunpla bao gồm nhiều runner và các phụ kiện liên quan, một tập sách nhỏ (manual) bên trong giới thiệu sơ lược về mẫu Gundam trong hộp và phần hướng dẫn cách lắp ráp.
                     Dòng gundam với các chi tiết hoàn hảo.
                     Các khớp cử động linh hoạt theo ý muốn.
                     Người chơi sẽ thỏa sức sáng tạo và đam mê.
                    TÊN SẢN PHẨM : MÔ HÌNH GUNDAM BANDAI 1/144 RG SAZABI MSN-04 SERIE GUNDAM U.C
                    THƯƠNG HIỆU : BANDAI – NHẬT BẢN
                    PHIÊN BẢN : RG
                    TỶ LỆ : 1:144
                    MÃ SP :
                    ĐỘ TUỔI : >14
                    KÍCH THƯỚC HỘP : 43x34x13 cm / 750gr
                    PHÂN LOẠI SP : LẮP RÁP
                    Siêu Mô Hình là một trong những shop thuộc hệ thống mô hình Gundamchat (GDC) với uy tín trên cộng đồng, đa dạng mẫu mã sản phẩm.`,
        images: [card10_1, card10_2, card10_3, card10_4],
    },
    {
        id: 11,
        img: card11_1,
        name: "HG CE 1/144 MIGHTY STRIKE FREEDOM GUNDAM",
        price: 600000,
        category: "HG",
        status: "in-stock",
        date: "2024-06-05",
        description: `ô Hình Lắp Ráp HG 1/144 MIGHTY STRIKE FREEDOM GUNDAM Bandai
                    - Sản phậm nhựa cao cấp với độ sắc nét cao
                    - Sản xuất bởi Bandai Namco – Nhật Bản Chính hãng
                    - Phát triển trí não cho trẻ hiệu quả đi đôi với niềm vui thích bất tận
                    - Rèn luyện tính kiên nhẫn cho người chơi
                    - Phân phối bởi Mô Hình GDC
                    - Thông tin cơ bản :
                    Mô hình gundam (gunpla) là một loại mô hình nhựa được gọi là Model kit, bao gồm nhiều mảnh nhựa rời được gọi là part (bộ phận), khi lắp ráp các part lại với nhau sẽ được mô hình hoàn chỉnh. Các mảnh nhựa rời này được gắn trên khung nhựa gọi là runner. Mỗi một hộp sản phẩm Gunpla bao gồm nhiều runner và các phụ kiện liên quan, một tập sách nhỏ (manual) bên trong giới thiệu sơ lược về mẫu Gundam trong hộp và phần hướng dẫn cách lắp ráp.
                    Dòng gundam với các chi tiết hoàn hảo.
                    Các khớp cử động linh hoạt theo ý muốn.
                    Người chơi sẽ thỏa sức sáng tạo và đam mê.

                    THƯƠNG HIỆU : BANDAI – NHẬT BẢN
                    PHIÊN BẢN : HG 1/144
                    Chiều cao: 13-16cm
                    PHÂN LOẠI SP : LẮP RÁP

                    QUÝ KHÁCH VUI LÒNG CHAT VỚI SHOP TRƯỚC KHI MUA HÀNG TRÁNH SẢN PHẨM HẾT HÀNG ĐỘT XUẤT
                    ----------
                    Quý khách có thể xem thêm các phụ kiện như kềm, nhíp, nhám, dao trong sản phẩm của shop
                    Lưu ý:
                    + Sản phẩm có những chi tiết nhỏ, quý khách kiểm tra trước khi lắp
                    + Hộp sản phẩm là giấy mỏng, có thể cấn móp trong quá trình vận chuyển, mong quý khách thông cảm
                    + Với những chi tiết lỗi có thể trao đổi trực tiếp với shop để hỗ trợ xử lý
                    ----------
                    =>> NHẬN ORDER TỪ 7-14 NGÀY ĐỐI VỚI NHỮNG MẶT HÀNG KHÔNG CÓ SẴN
                    =>> MỌI CHI TIẾT XIN LIÊN HỆ VỚI CỬA HÀNG`,
        images: [card11_1, card11_2, card11_3, card11_4],
    },
    {
        id: 12,
        img: card12_1,
        name: "HG CE 1/144 IMMORTAL JUSTICE GUNDAM",
        price: 510000,
        category: "HG",
        status: "in-stock",
        date: "2024-02-16",
        description: `Mô Hình Lắp Ráp HG 1/144 IMMORTAL JUSTICE GUNDAM Bandai
                    Phát hành tháng 1-2024
                    QUÝ KHÁCH VUI LÒNG CHAT VỚI SHOP TRƯỚC KHI MUA HÀNG TRÁNH SẢN PHẨM HẾT HÀNG ĐỘT XUẤT
                    ----------
                    "RISING FREEDOM GUNDAM" từ "Mobile Suit GTA SEED FREEDOM" sẽ được phát hành từ dòng HG
                    - Mô hình nhựa lắp ghép - Gunpla
                    - Xuất Xứ: Nhật Bản
                    - Thương Hiệu: Chính Hãng Bandai Nhật Bản 100%
                    - Chất Liệu: PS; ABS; PP; PVC không độc hại, an toàn cho trẻ em
                    - Phù hợp với độ tuổi từ 8 tuổi trở lên
                    - Tỉ lệ HG, sau khi lắp ráp cao khoảng 14cm
                    - Sản phẩm có khớp có thể cử động nhiều dáng khác nhau, tạo nhiều dáng đẹp
                    - Đây là mô hình mới chưa được lắp ráp, người chơi lắp ráp theo sách hướng dẫn kèm theo
                    - Sản phẩm lắp ghép dùng chốt bấm không cần dùng keo dán
                    - Yêu cầu dụng cụ hỗ trợ cơ bản như kềm chuyên dụng và dao chuyên dụng

                    Lưu ý:
                    + Sản phẩm có những chi tiết nhỏ, quý khách kiểm tra trước khi lắp
                    + Hộp sản phẩm là giấy mỏng, có thể cấn móp trong quá trình vận chuyển, mong quý khách thông cảm
                    + Với những chi tiết lỗi có thể trao đổi trực tiếp với shop để hỗ trợ xử lý
                    ----------
                    =>> NHẬN ORDER TỪ 7-14 NGÀY ĐỐI VỚI NHỮNG MẶT HÀNG KHÔNG CÓ SẴN
                    =>> MỌI CHI TIẾT XIN LIÊN HỆ VỚI CỬA HÀNG`,
        images: [card12_1, card12_2, card12_3, card12_4],
    },
    {
        id: 13,
        img: card13_1,
        name: "HG 1/144 OO GUNDAM SEVEN SWORD/G",
        price: 425000,
        category: "HG",
        status: "out-of-stock",
        date: "2024-07-19",
        description: "Mô tả sản phẩm HG 1/144 OO GUNDAM SEVEN SWORD/G",
        images: [card13_1, card13_2, card13_3, card13_4],
    },
    {
        id: 14,
        img: card14_1,
        name: "HG CE 1/144 RISING FREEDOM GUNDAM",
        price: 560000,
        category: "HG",
        status: "in-stock",
        date: "2024-08-23",
        description: `Mô Hình Lắp Ráp HG 1/144 RISING FREEDOM GUNDAM Bandai
                    Phát hành tháng 1-2024
                    QUÝ KHÁCH VUI LÒNG CHAT VỚI SHOP TRƯỚC KHI MUA HÀNG TRÁNH SẢN PHẨM HẾT HÀNG ĐỘT XUẤT
                    ----------
                    "RISING FREEDOM GUNDAM" từ "Mobile Suit GTA SEED FREEDOM" sẽ được phát hành từ dòng HG
                    - Mô hình nhựa lắp ghép - Gunpla
                    - Xuất Xứ: Nhật Bản
                    - Thương Hiệu: Chính Hãng Bandai Nhật Bản 100%
                    - Chất Liệu: PS; ABS; PP; PVC không độc hại, an toàn cho trẻ em
                    - Phù hợp với độ tuổi từ 8 tuổi trở lên
                    - Tỉ lệ HG, sau khi lắp ráp cao khoảng 14cm
                    - Sản phẩm có khớp có thể cử động nhiều dáng khác nhau, tạo nhiều dáng đẹp
                    - Đây là mô hình mới chưa được lắp ráp, người chơi lắp ráp theo sách hướng dẫn kèm theo
                    - Sản phẩm lắp ghép dùng chốt bấm không cần dùng keo dán
                    - Yêu cầu dụng cụ hỗ trợ cơ bản như kềm chuyên dụng và dao chuyên dụng

                    Lưu ý:
                    + Sản phẩm có những chi tiết nhỏ, quý khách kiểm tra trước khi lắp
                    + Hộp sản phẩm là giấy mỏng, có thể cấn móp trong quá trình vận chuyển, mong quý khách thông cảm
                    + Với những chi tiết lỗi có thể trao đổi trực tiếp với shop để hỗ trợ xử lý
                    ----------
                    =>> NHẬN ORDER TỪ 7-14 NGÀY ĐỐI VỚI NHỮNG MẶT HÀNG KHÔNG CÓ SẴN
                    =>> MỌI CHI TIẾT XIN LIÊN HỆ VỚI CỬA HÀNG`,
        images: [card14_1, card14_2, card14_3, card14_4],
    },
    {
        id: 15,
        img: card15_1,
        name: "SD EX STANDARD RISING FREEDOM GUNDAM",
        price: 175000,
        category: "SD",
        status: "out-of-stock",
        date: "2024-12-25",
        description: `Mô Hình Lắp Ráp SD GUNDAM EX-STANDARD RISING FREEDOM GUNDAM Bandai 4573102662866
                    - Sản phậm nhựa cao cấp với độ sắc nét cao
                    - Sản xuất bởi Bandai Namco – Nhật Bản Chính hãng
                    - An toàn với trẻ em
                    - Phát triển trí não cho trẻ hiệu quả đi đôi với niềm vui thích bất tận
                    - Rèn luyện tính kiên nhẫn cho người chơi
                    - Phân phối bởi Mô Hình GDC
                    - Thông tin cơ bản :
                     Mô hình gundam (gunpla) là một loại mô hình nhựa được gọi là Model kit, bao gồm nhiều mảnh nhựa rời được gọi là part (bộ phận), khi lắp ráp các part lại với nhau sẽ được mô hình hoàn chỉnh. Các mảnh nhựa rời này được gắn trên khung nhựa gọi là runner. Mỗi một hộp sản phẩm Gunpla bao gồm nhiều runner và các phụ kiện liên quan, một tập sách nhỏ (manual) bên trong giới thiệu sơ lược về mẫu Gundam trong hộp và phần hướng dẫn cách lắp ráp.
                     Dòng gundam với các chi tiết hoàn hảo.
                     Các khớp cử động linh hoạt theo ý muốn.
                     Người chơi sẽ thỏa sức sáng tạo và đam mê.

                    THƯƠNG HIỆU : BANDAI – NHẬT BẢN
                    PHIÊN BẢN : SD [SUPER DEFORM]
                    Chiều cao: 8-10cm
                    PHÂN LOẠI SP : LẮP RÁP

                    QUÝ KHÁCH VUI LÒNG CHAT VỚI SHOP TRƯỚC KHI MUA HÀNG TRÁNH SẢN PHẨM HẾT HÀNG ĐỘT XUẤT
                    ----------
                    Quý khách có thể xem thêm các phụ kiện như kềm, nhíp, nhám, dao trong sản phẩm của shop
                    Lưu ý:
                    + Sản phẩm có những chi tiết nhỏ, quý khách kiểm tra trước khi lắp
                    + Hộp sản phẩm là giấy mỏng, có thể cấn móp trong quá trình vận chuyển, mong quý khách thông cảm
                    + Với những chi tiết lỗi có thể trao đổi trực tiếp với shop để hỗ trợ xử lý
                    ----------
                    =>> NHẬN ORDER TỪ 7-14 NGÀY ĐỐI VỚI NHỮNG MẶT HÀNG KHÔNG CÓ SẴN
                    =>> MỌI CHI TIẾT XIN LIÊN HỆ VỚI CỬA HÀNG`,
        images: [card15_1, card15_2, card15_3, card15_4],
    },
    {
        id: 16,
        img: card16_1,
        name: "SD EX STANDARD WING GUNDAM ZERO",
        price: 165000,
        category: "SD",
        status: "in-stock",
        date: "2024-04-01",
        description: "Mô tả sản phẩm SD EX STANDARD WING GUNDAM ZERO",
        images: [card16_1, card16_2, card16_3, card16_4],
    },
];

const ProductDetail = () => {
    const { id } = useParams(); // Lấy ID sản phẩm từ URL
    const navigate = useNavigate(); // Điều hướng

    const [product, setProduct] = useState(null);
    const [mainImage, setMainImage] = useState(null); // Ảnh chính của sản phẩm

    const [quantity, setQuantity] = useState(1); // State cho số lượng

    const { addToCart } = useCart(); // Lấy hàm addToCart

    // Xử lý khi thêm sản phẩm vào giỏ
    const handleAddToCart = () => {
        if (product) {
            // Gửi sản phẩm với số lượng đã chọn vào giỏ
            addToCart({ ...product, quantity });
        }
    };

    // Xử lý tăng số lượng sản phẩm
    const handleIncreaseQuantity = () => {
        setQuantity((prevQuantity) => prevQuantity + 1); // Tăng số lượng
    };

    // Xử lý giảm số lượng sản phẩm
    const handleDecreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity((prevQuantity) => prevQuantity - 1); // Giảm số lượng nếu lớn hơn 1
        }
    };

    const [similarProducts, setSimilarProducts] = useState([]);

    // Xử lý khi ID sản phẩm thay đổi
    useEffect(() => {
        const selectedProduct = allProducts.find((item) => item.id === parseInt(id));
        if (selectedProduct) {
            setProduct(selectedProduct);
            setMainImage(selectedProduct.img);

            // Lọc sản phẩm tương tự theo category (case-insensitive)
            const filteredProducts = allProducts.filter(
                (item) => item.category.toLowerCase() === selectedProduct.category.toLowerCase() && item.id !== selectedProduct.id // Loại bỏ chính sản phẩm hiện tại
            );

            setSimilarProducts(filteredProducts);
        } else {
            navigate("/"); // Chuyển hướng về trang chủ nếu sản phẩm không tồn tại
        }
    }, [id, navigate]);

    // Cập nhật ảnh chính khi click vào thumbnail
    const [selectedImage, setSelectedImage] = useState(null);

    const handleThumbnailClick = (image) => {
        setMainImage(image); // Cập nhật ảnh chính
        setSelectedImage(image); // Cập nhật ảnh được chọn
    };

    if (!product) return <p>Đang tải...</p>;

    return (
        <>
            <Breadcrumb name="Chi tiết sản phẩm" />
            <div className="container">
                <div className="row">
                    {/* Hiển thị chi tiết sản phẩm */}
                    <div className="col-lg-6">
                        <div className="product__detail__pic">
                            <img src={mainImage} alt={product.name} />
                        </div>
                        <div className="main">
                            {product.images.map((image, index) => (
                                <div className="col-3" key={index}>
                                    <img
                                        src={image}
                                        alt={`Thumbnail ${index + 1}`}
                                        className={`thumbnail-image`}
                                        onClick={() => setMainImage(image)} // Cập nhật ảnh chính khi click vào thumbnail
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="product__detail__text">
                            <h1>{product.name}</h1>
                            <p className="price__p">
                                Giá: <span className="price_v">{formater(product.price)}</span>
                            </p>
                            <p className="category__p">Loại: {product.category}</p>
                            <p className="status__p">
                                Tình trạng:
                                <span className={product.status === "in-stock" ? "status-in-stock" : "status-out-of-stock"}>
                                    {product.status === "in-stock" ? "Còn hàng" : "Hết hàng"}
                                </span>
                            </p>
                            <div className="quantity">
                                <span className="qtybtn" onClick={handleDecreaseQuantity}>
                                    -
                                </span>
                                <input type="number" value={quantity} readOnly />
                                <span className="qtybtn" onClick={handleIncreaseQuantity}>
                                    +
                                </span>
                            </div>
                            <button type="button" className="button-submit" onClick={handleAddToCart}>
                                Thêm vào giỏ hàng
                            </button>
                        </div>
                    </div>
                </div>

                {/* Hiển thị mô tả sản phẩm */}
                <div className="product__detail__description">
                    <h2>Thông tin chi tiết</h2>
                    {product.description.split("\n").map((line, index) => (
                        <p key={index}>{line}</p>
                    ))}
                </div>

                {/* Hiển thị sản phẩm tương tự */}
                <div className="similar__product">
                    <h2>Sản phẩm tương tự</h2>
                    <div className="row">
                        {similarProducts.length > 0 ? (
                            similarProducts.map((item) => (
                                <div className="col-lg-4" key={item.id}>
                                    <ProductCard id={item.id} category={item.category} name={item.name} img={item.img} price={item.price} />
                                </div>
                            ))
                        ) : (
                            <p>Không có sản phẩm tương tự nào.</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductDetail;
