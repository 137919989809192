import { memo, useState } from "react";
import { useCart } from "context/CartContext";
import { formater } from "utils/formater";
import "./style.scss";
import Breadcrumb from "../theme/breadcrumb";

const CheckoutPage = () => {
    const { cart } = useCart(); // Lấy giỏ hàng từ context
    const [billingInfo, setBillingInfo] = useState({
        name: "",
        email: "",
        address: "",
        phone: "",
        note: "",
    });

    const [paymentMethod, setPaymentMethod] = useState("creditCard");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBillingInfo({
            ...billingInfo,
            [name]: value,
        });
    };

    const handlePaymentChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!billingInfo.name || !billingInfo.email || !billingInfo.address || !billingInfo.phone) {
            alert("Vui lòng nhập đầy đủ thông tin!");
            return;
        }
        // Xử lý thanh toán (gửi dữ liệu qua API nếu cần)
        alert(`Đơn hàng của bạn đã được thanh toán thành công bằng phương thức: ${paymentMethod}`);
    };

    const totalAmount = cart.reduce((total, product) => total + product.price * product.quantity, 0);

    return (
        <>
            <Breadcrumb name="Thanh toán" />

            <div className="container">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        {/* Cột nhập thông tin khách hàng */}
                        <div className="col-lg-6">
                            <h3>Thông tin thanh toán</h3>
                            <div className="checkout__input">
                                <label>
                                    Họ và tên: <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Vui lòng nhập họ và tên"
                                    value={billingInfo.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="checkout__input">
                                <label>
                                    Email: <span className="required">*</span>
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Vui lòng nhập email"
                                    value={billingInfo.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="checkout__input">
                                <label>
                                    Địa chỉ: <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Vui lòng nhập địa chỉ"
                                    value={billingInfo.address}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="checkout__input">
                                <label>
                                    Số điện thoại: <span className="required">*</span>
                                </label>
                                <input
                                    type="tel"
                                    name="phone"
                                    placeholder="Vui lòng nhập số điện thoại"
                                    value={billingInfo.phone}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="checkout__input">
                                <label>Ghi chú:</label>
                                <textarea
                                    rows={5}
                                    name="note"
                                    placeholder="Nhập ghi chú"
                                    value={billingInfo.note}
                                    onChange={handleInputChange}
                                ></textarea>
                            </div>

                            {/* Phương thức thanh toán */}
                            <div className="checkout__payment-methods">
                                <h4>Phương thức thanh toán</h4>
                                <div className="payment-options">
                                    {["Thanh toán khi nhận hàng", "Thẻ tín dụng", "Ví điện tử"].map((method, index) => (
                                        <div
                                            key={index}
                                            className={`payment-option ${paymentMethod === method ? "selected" : ""}`}
                                            onClick={() => setPaymentMethod(method)}
                                        >
                                            {method}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Cột thông tin giỏ hàng */}
                        <div className="col-lg-6">
                            <div className="checkout__order">
                                <h3>Đơn hàng</h3>
                                <ul className="order-list">
                                    {cart.map((product) => (
                                        <li key={product.id} className="order-item">
                                            <img src={product.img} alt={product.name} className="product-image" />
                                            <div className="product-details">
                                                <h4 className="product-name">{product.name}</h4>
                                                <p className="product-quantity">
                                                    Số lượng: <span className="quantity__p"> {product.quantity}</span>
                                                </p>
                                                <p className="product-price">
                                                    Giá: <span className="price__p">{formater(product.price)}</span>
                                                </p>
                                                <p className="product-total">
                                                    Thành tiền: <span className="total__p"> {formater(product.price * product.quantity)}</span>
                                                </p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <div className="checkout__order__subtotal">
                                    <h4>Tổng đơn</h4>
                                    <b>{formater(totalAmount)}</b>
                                </div>
                                <button type="submit" className="button-submit">
                                    Đặt hàng
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default memo(CheckoutPage);
