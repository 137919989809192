import { memo, useState } from "react";
import Breadcrumb from "../theme/breadcrumb";
import "./style.scss";
import { AiOutlineClose } from "react-icons/ai";
import { formater } from "utils/formater";
import { useCart } from "context/CartContext";
import { Link } from "react-router-dom";
import { ROUTERS } from "utils/router";

const ShoppingCartPage = () => {
    const { cart, removeFromCart, updateCartQuantity } = useCart();
    const [inputQuantities, setInputQuantities] = useState({});

    // Hàm xử lý khi thay đổi số lượng
    const handleQuantityChange = (productId, quantity) => {
        setInputQuantities((prev) => ({ ...prev, [productId]: quantity }));
        updateCartQuantity(productId, quantity); // Cập nhật số lượng trong giỏ
    };

    return (
        <>
            <Breadcrumb name="Giỏ hàng" />
            <div className=" container">
                <div className="table__cart">
                    <table>
                        <thead>
                            <tr>
                                <th>Tên</th>
                                <th>Giá</th>
                                <th>Số lượng</th>
                                <th>Thành tiền</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {cart.map((product) => (
                                <tr key={product.id}>
                                    <td className="shopping__cart__item">
                                        <img src={product.img} alt={product.name} />
                                        <h4>{product.name}</h4>
                                    </td>
                                    <td>{formater(product.price)}</td>
                                    <td>
                                        <div className="quantity">
                                            <span className="qtybtn" onClick={() => handleQuantityChange(product.id, product.quantity - 1)}>
                                                -
                                            </span>
                                            <input
                                                type="number"
                                                value={inputQuantities[product.id] || product.quantity}
                                                onChange={(e) => handleQuantityChange(product.id, parseInt(e.target.value))}
                                            />
                                            <span className="qtybtn" onClick={() => handleQuantityChange(product.id, product.quantity + 1)}>
                                                +
                                            </span>
                                        </div>
                                    </td>
                                    <td>{formater(product.price * product.quantity)}</td>
                                    <td className="icon_close">
                                        <AiOutlineClose onClick={() => removeFromCart(product.id)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="shopping__add">
                            <h3>Mã giảm giá</h3>
                            <div className="shopping__discount">
                                <input type="text" placeholder="Nhập mã giảm giá" />
                                <button type="button" className="button-submit">
                                    Áp dụng
                                </button>
                            </div>
                        </div>
                        <div className="back">
                            <button className="button-submit" type="button">
                                Quay lại
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="shopping__checkout">
                            <h3>Tổng đơn:</h3>
                            <ul>
                                <li>
                                    Số lượng: <span>{cart.reduce((total, product) => total + product.quantity, 0)}</span>
                                </li>
                                <li>
                                    Thành tiền: <span>{formater(cart.reduce((total, product) => total + product.price * product.quantity, 0))}</span>
                                </li>
                            </ul>
                            <Link to={ROUTERS.USERS.CHECKOUT_PAGE}>
                                <button type="submit" className="button-submit">
                                    Thanh toán
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(ShoppingCartPage);
