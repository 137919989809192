import { memo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./style.scss";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import card1 from "assets/users/images/card/card1.png";
import card2 from "assets/users/images/card/card2.png";
import card3 from "assets/users/images/card/card3.png";
import card4 from "assets/users/images/card/card4.png";
import card5 from "assets/users/images/card/card5.png";
import card6 from "assets/users/images/card/card6.png";
import card7 from "assets/users/images/card/card7.png";
import card8 from "assets/users/images/card/card8.png";
import card9 from "assets/users/images/card/card9.png";
import card10 from "assets/users/images/card/card10.png";
import card11 from "assets/users/images/card/card11.png";
import card12 from "assets/users/images/card/card12.png";
import card13 from "assets/users/images/card/card13.png";
import card14 from "assets/users/images/card/card14.png";
import card15 from "assets/users/images/card/card15.png";
import card16 from "assets/users/images/card/card16.png";
import { ProductCard } from "component";

const HomePage = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const featProducts = {
        productSet1: {
            title: "Sản phẩm nổi bật",
            products: [
                {
                    id: 1,
                    img: card1,
                    name: "MGEX 1/100 Strike Freedom Gundam",
                    price: 3600000,
                    category: "MGEX",
                    status: "in-stock",
                    date: "2024-11-01",
                },
                {
                    id: 2,
                    img: card2,
                    name: "PG 1/60 RX-0 UNICORN GUNDAM",
                    price: 4380000,
                    category: "PG",
                    status: "in-stock",
                    date: "2024-12-01",
                },
                {
                    id: 5,
                    img: card5,
                    name: "PG 1/60 GUNDAM EXIA",
                    price: 4550000,
                    category: "PG",
                    status: "in-stock",
                    date: "2024-07-20",
                },
                {
                    id: 6,
                    img: card6,
                    name: "MG 1/100 WING ZERO EW Verka",
                    price: 1250000,
                    category: "MG",
                    status: "out-of-stock",
                    date: "2024-10-25",
                },
            ],
        },
        productSet2: {
            title: "Sản phẩm mới",
            products: [
                {
                    id: 8,
                    img: card8,
                    name: "RG 1/144 RX-93FF NU GUNDAM SIDE F - GUNDAM FUKUOKA LIMITED",
                    price: 2080000,
                    category: "RG",
                    status: "in-stock",
                    date: "2024-10-25",
                },
                {
                    id: 14,
                    img: card14,
                    name: "HG CE 1/144 RISING FREEDOM GUNDAM",
                    price: 560000,
                    category: "HG",
                    status: "in-stock",
                    date: "2024-08-23",
                },
            ],
        },
        productSet3: {
            title: "Sản phẩm bán chạy",
            products: [
                {
                    id: 10,
                    img: card10,
                    name: "RG 1/144 SAZABI",
                    price: 1250000,
                    category: "RG",
                    status: "in-stock",
                    date: "2024-4-12",
                },
            ],
        },
    };

    const renderFeaturedProducts = (data) => {
        const tabList = [];
        const tabPanels = [];

        Object.keys(data).forEach((key, index) => {
            console.log(key, index);
            tabList.push(<Tab key={index}>{data[key].title}</Tab>);

            const tabPanel = [];

            data[key].products.forEach((item, j) => {
                tabPanel.push(
                    <div className="col-lg-3" key={item.id}>
                        <ProductCard id={item.id} name={item.name} img={item.img} price={item.price} />
                    </div>
                );
            });
            tabPanels.push(tabPanel);
        });

        return (
            <Tabs>
                <TabList>{tabList}</TabList>
                {tabPanels.map((item, key) => (
                    <TabPanel>
                        <div className="row">{item}</div>
                    </TabPanel>
                ))}
            </Tabs>
        );
    };
    return (
        <>
            {/* Categories Begin */}
            <div className="container container__categories_slider">
                <h2>Danh mục</h2>
                <Carousel responsive={responsive} className="categories_slider">
                    <div className="categories_slider_item">
                        <Link to={`/san-pham?category=SD`}>
                            <img src="/images/categories/cat1.png" style={{ width: "150px", height: "100px", borderRadius: "8px" }} />
                            <h3>Super Deformed</h3>
                        </Link>
                    </div>
                    <div className="categories_slider_item">
                        <Link to={`/san-pham?category=HG`}>
                            <img src="/images/categories/cat2.png" style={{ width: "150px", height: "100px", borderRadius: "8px" }} />
                            <h3>High Grade</h3>
                        </Link>
                    </div>
                    <div className="categories_slider_item">
                        <Link to={`/san-pham?category=RG`}>
                            <img src="/images/categories/cat3.png" style={{ width: "150px", height: "100px", borderRadius: "8px" }} />
                            <h3>Real Grade</h3>
                        </Link>
                    </div>
                    <div className="categories_slider_item">
                        <Link to={`/san-pham?category=MG`}>
                            <img src="/images/categories/cat4.png" style={{ width: "150px", height: "100px", borderRadius: "8px" }} />
                            <h3>Master Grade</h3>
                        </Link>
                    </div>
                    <div className="categories_slider_item">
                        <Link to={`/san-pham?category=MGSD`}>
                            <img src="/images/categories/cat5.png" style={{ width: "150px", height: "100px", borderRadius: "8px" }} />
                            <h3>Master Grade SD</h3>
                        </Link>
                    </div>
                    <div className="categories_slider_item">
                        <Link to={`/san-pham?category=MGEX`}>
                            <img src="/images/categories/cat6.png" style={{ width: "250px", height: "100px", borderRadius: "8px" }} />
                            <h3>Master Grade Extreme</h3>
                        </Link>
                    </div>
                    <div className="categories_slider_item">
                        <Link to={`/san-pham?category=PG`}>
                            <img src="/images/categories/cat7.png" style={{ width: "150px", height: "100px", borderRadius: "8px" }} />
                            <h3>Perfect Grade</h3>
                        </Link>
                    </div>
                </Carousel>
            </div>
            {/* Categories End */}

            {/*Featured Begin*/}
            <div className="container">
                <div className="featured">
                    <div className="section-title">
                        <h2>Hạng mục sản phẩm</h2>
                    </div>
                    {renderFeaturedProducts(featProducts)}
                </div>
            </div>

            {/*Featured End*/}
            {/*Banner Begin*/}
            <div className="container">
                <div className="col-lg-12">
                    <div className="banner">
                        <Link to="">
                            <img className="pic1" src="/images/banner/2.png" alt="" width="100%" />
                        </Link>

                        <Link to="">
                            <img className="pic2" src="/images/banner/1.png" alt="" width="100%" />
                        </Link>
                    </div>
                </div>
            </div>

            {/*Banner End*/}
        </>
    );
};

export default memo(HomePage);
