export const ROUTERS = {
    USERS: {
        HOME: "",
        PROFILE: "thong-tin-ca-nhan",
        PRODUCTS: "/san-pham",
        PRODUCT: "/san-pham/chi-tiet/:id",
        SHOPPING_CART: "/gio-hang",
        CHECKOUT_PAGE: "/thanh-toan",
        BLOG: "/bai-viet",
        CONTACT: "/lien-he",
        LOGIN: "/dang-nhap",
        REGISTER: "/dang-ky",
    },
};
