import { memo } from "react";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineShoppingCart } from "react-icons/ai";
import { formater } from "utils/formater";
import { useCart } from "context/CartContext"; // Import the useCart hook
import "./style.scss";

const ProductCard = ({ img, name, price, id }) => {
    const { addToCart } = useCart();

    const handleAddToCart = () => {
        const product = { id, img, name, price, quantity: 1 };
        addToCart(product);
    };

    return (
        <div className="featured__item">
            <div className="featured__item__pic" style={{ backgroundImage: `url(${img})` }}>
                <ul className="featured__item__pic__hover">
                    <li>
                        <Link to={`/san-pham/chi-tiet/${id}`}>
                            <AiOutlineEye />
                        </Link>
                    </li>
                    <li>
                        <Link to="#" onClick={handleAddToCart}>
                            <AiOutlineShoppingCart />
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="featured__item__text">
                <h6>
                    <Link to={`/san-pham/chi-tiet/${id}`}>{name}</Link>
                </h6>
                <h5>{formater(price)}</h5>
            </div>
        </div>
    );
};

export default memo(ProductCard);
