import { memo, useState } from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import { ROUTERS } from "utils/router";
import "./style.scss";

const Footer = () => {
    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!email) {
            alert("Please enter a valid email address.");
            return;
        }
        const templateParams = {
            to_email: email,
            subject: "Khuyến mãi và ưu đãi",
            message: "Cảm ơn bạn đã đăng ký nhận thông tin khuyến mãi từ Gunpla Shop!",
        };

        emailjs.send("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", templateParams, "YOUR_USER_ID").then(
            (response) => {
                alert("Cảm ơn bạn đã đăng ký nhận khuyến mãi!");
                setEmail("");
            },
            (error) => {
                console.error("Email sending error: ", error);
                alert("Có lỗi xảy ra khi gửi email. Vui lòng thử lại.");
            }
        );
    };

    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="footer__about">
                            <div className="footer__about__logo">
                                <img
                                    src="https://gunplastory.com/wp-content/uploads/2021/08/gunplastory-logo.webp"
                                    alt=""
                                    width="200px"
                                    height="100%"
                                />
                            </div>
                            <ul>
                                <li>Địa chỉ: Ninh Kiều, Cần Thơ</li>
                                <li>Phone: 0888033529</li>
                                <li>Email: gunplashop@gmail.com</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="footer__widget">
                            <h6>Cửa Hàng</h6>
                            <ul>
                                <li>
                                    <Link to="">Liên hệ</Link>
                                </li>
                                <li>
                                    <Link to="">Thông tin về chúng tôi</Link>
                                </li>
                                <li>
                                    <Link to="">Sản phẩm kinh doanh</Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link to="">Thông tin tài khoản</Link>
                                </li>
                                <li>
                                    <Link to="">Giỏ hàng</Link>
                                </li>
                                <li>
                                    <Link to="">Danh sách ưa thích</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                        <div className="footer__widget">
                            <h6>Ưu đãi & khuyến mãi</h6>
                            <p>Đăng kí thông tin tại đây</p>
                            <form onSubmit={handleSubmit}>
                                <div className="input-group">
                                    <input type="email" placeholder="Nhập email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <button type="submit" className="button-submit">
                                        Đăng kí
                                    </button>
                                </div>
                                <div className="footer__widget__social">
                                    <div>
                                        <Link to={""}>
                                            <FaFacebook />
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to={""}>
                                            <FaInstagram />
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to={""}>
                                            <FaTwitter />
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default memo(Footer);
