import { memo } from "react";
import Header from "../header";
import Footer from "../footer";
import { useLocation } from "react-router-dom";

const MasterLayout = ({ children, ...props }) => {
    const location = useLocation();

    // Kiểm tra nếu đường dẫn hiện tại là /login hoặc /register
    const isLoginOrRegister = location.pathname === "/dang-nhap" || location.pathname === "/dang-ky";
    return (
        <div {...props}>
            {/* Ẩn header và footer cho trang đăng nhập và đăng ký */}
            {!isLoginOrRegister && <Header />}
            {children}
            {!isLoginOrRegister && <Footer />}
        </div>
    );
};
export default memo(MasterLayout);
