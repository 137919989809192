import { memo, useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";

const LoginPage = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        login(username, password);
        navigate("/");
    };

    // Hàm xử lý đăng nhập qua Google
    const handleGoogleLogin = (response) => {
        console.log("Google Login response:", response);
        if (response?.credential) {
            const googleToken = response.credential;
            console.log("Google Token: ", googleToken);

            // Lưu trữ thông tin người dùng vào sessionStorage hoặc localStorage
            sessionStorage.setItem("googleToken", googleToken);

            navigate("/");
        }
    };

    return (
        <GoogleOAuthProvider
            clientId="117099397168-h217kn3mdpg2vmfed794u6cfr0tfgdon.apps.googleusercontent.com"
            redirectUri="http://localhost:3000/auth/callback"
        >
            {/* Thêm GoogleOAuthProvider ở đây */}
            <div className="container_login">
                <div className="login-page">
                    <div className="form-container">
                        <div className="login-container">
                            <h2>Đăng nhập</h2>
                            <form onSubmit={handleLogin}>
                                <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <button type="submit" className="button-submit">
                                    Đăng nhập
                                </button>
                            </form>

                            <div className="social-login">
                                <GoogleLogin onSuccess={handleGoogleLogin} onError={(error) => console.log("Google Login Error:", error)} useOneTap />
                            </div>

                            <p>
                                Không có tài khoản?
                                <a href="/dang-ky" className="register-link">
                                    Đăng ký
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="image-container">
                        <img src="/images/hero/7.jpg" alt="Gundam" className="gundam-image" />
                    </div>
                </div>
            </div>
        </GoogleOAuthProvider>
    );
};

export default memo(LoginPage);
