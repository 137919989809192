import { memo, useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";

const RegisterPage = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const navigate = useNavigate();

    const handleRegister = (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            console.log("Passwords do not match!");
            return;
        }

        // Giả lập lưu người dùng vào localStorage
        const newUser = { username, password };
        localStorage.setItem("user", JSON.stringify(newUser)); // Lưu thông tin vào localStorage

        console.log("Đăng ký thành công với: ", username, password);

        navigate("/dang-nhap");
    };
    return (
        <div className="container_register">
            <div className="register-page">
                <div className="form-container">
                    <div className="register-container">
                        <h2>Đăng ký</h2>
                        <form onSubmit={handleRegister}>
                            <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <input
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <button type="submit" className="button-submit">
                                Đăng ký
                            </button>
                        </form>

                        <p>
                            Đã có tài khoản?
                            <a href="/dang-nhap" className="login-link">
                                Đăng nhập
                            </a>
                        </p>
                    </div>
                </div>
                <div className="image-container">
                    <img src="/images/hero/7.jpg" alt="Gundam" className="gundam-image" />
                </div>
            </div>
        </div>
    );
};

export default memo(RegisterPage);
