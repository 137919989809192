import React, { useState } from "react";
import "./style.scss";
import Breadcrumb from "../theme/breadcrumb";

const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission (e.g., send data to a server)
        alert("Form submitted");
    };

    return (
        <>
            {" "}
            <Breadcrumb name="Liên hệ" />
            <div className="container">
                <div className="contact-page">
                    <div className="contact-info">
                        <h2>Follow Us</h2>
                        <div className="social-links">
                            <a href="https://www.facebook.com/YourPage" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-facebook-f"></i> Facebook
                            </a>
                            <a href="https://www.instagram.com/YourProfile" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-instagram"></i> Instagram
                            </a>
                            <a href="https://twitter.com/YourProfile" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-twitter"></i> Twitter
                            </a>
                        </div>
                    </div>
                    <h1>Contact Us</h1>
                    <div className="map-container">
                        <h2>Our Location</h2>
                        <iframe
                            title="Google Map Location"
                            width="100%"
                            height="450"
                            src="https://www.google.com/maps/embed/v1/place?q=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=YOUR_API_KEY"
                            frameBorder="0"
                            style={{ border: 0 }}
                            allowFullScreen
                        ></iframe>
                    </div>

                    <div className="contact-form">
                        <h2>Send Us a Message</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-field">
                                <label htmlFor="name">Name</label>
                                <input type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} required />
                            </div>
                            <div className="form-field">
                                <label htmlFor="email">Email</label>
                                <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} required />
                            </div>
                            <div className="form-field">
                                <label htmlFor="message">Message</label>
                                <textarea id="message" name="message" value={formData.message} onChange={handleInputChange} required />
                            </div>
                            <button type="submit" className="submit-btn">
                                Send Message
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactPage;
